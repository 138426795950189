import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.SET_BREADCRUMBS: {
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    }
    default: {
      return state;
    }
  }
}
