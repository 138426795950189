<!-- Desktop & tablet landscape -->
<div
  *ngIf="(isWeb || isTabletLandscape) && enableVerticalMenu"
  class="relative flex h-full"
>
  <button
    *ngIf="!verticalMenuOpen && isWeb && animationDone"
    class="z-10 flex h-full w-5 items-center justify-center bg-brand-blue-550"
    (click)="toggleVerticalMenu()"
  >
    <img
      src="/assets/icons/Reishauer_Icons-SubMenuRight.svg"
      class="h-4 w-4"
      alt="Submenu icon"
    />
  </button>
  <button
    *ngIf="isTabletLandscape"
    [ngClass]="verticalMenuOpen ? 'invisible' : 'visible'"
    class="flex h-full w-5 items-center justify-center bg-brand-blue-550"
    (click)="toggleVerticalMenu()"
  >
    <img
      src="/assets/icons/Reishauer_Icons-SubMenuRight.svg"
      class="h-4 w-4"
      alt="Submenu icon"
    />
  </button>
  <div
    [hidden]="!verticalMenuOpen && animationDone"
    [@openClose]="verticalMenuOpen"
    (@openClose.start)="animationDone = false"
    (@openClose.done)="animationDone = true"
    [ngClass]="{
      'absolute z-50': isTabletLandscape,
      flex: verticalMenuOpen
    }"
    class="h-full w-[300px] flex-col bg-brand-blue-550"
  >
    <div class="flex h-24 justify-end p-2">
      <button
        type="button"
        class="h-8 rounded-full bg-brand-blue-730 p-2"
        (click)="toggleVerticalMenu()"
      >
        <img
          src="/assets/icons/Reishauer_Icons-SubMenuLeft.svg"
          class="h-4 w-4"
          alt="Submenu icon"
        />
      </button>
    </div>

    <div *ngIf="animationDone" class="px-big">
      <p class="text-white">{{ "verticalMenu.views" | translate }}</p>

      <div
        class="flex flex-col gap-4 whitespace-nowrap font-brand-regular text-[17px] text-white"
      >
        <a
          (click)="toggleVerticalMenu()"
          [routerLink]="['/organizations']"
          routerLinkActive="bg-white text-brand-blue-550"
          [routerLinkActiveOptions]="{ exact: true }"
          class="w-full rounded-md border border-white bg-none px-4 py-2"
        >
          {{ "verticalMenu.controlRoomView" | translate }}
        </a>
        <a
          (click)="toggleVerticalMenu()"
          [routerLink]="['organizations']"
          [queryParams]="{ filter: 'detailed' }"
          routerLinkActive="bg-white text-brand-blue-550"
          class="w-full rounded-md border border-white bg-none px-4 py-2"
        >
          {{ "verticalMenu.detailedView" | translate }}
        </a>
        <a
          (click)="toggleVerticalMenu()"
          [routerLink]="['organizations']"
          [queryParams]="{ filter: 'expiring' }"
          routerLinkActive="bg-white text-brand-blue-550"
          class="w-full rounded-md border border-white bg-none px-4 py-2"
        >
          {{ "verticalMenu.expiringView" | translate }}
        </a>
        <a
          (click)="toggleVerticalMenu()"
          [routerLink]="['organizations']"
          [queryParams]="{ filter: 'expired' }"
          routerLinkActive="bg-white text-brand-blue-550"
          class="w-full rounded-md border border-white bg-none px-4 py-2"
        >
          {{ "verticalMenu.soonExpiringView" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>

<!-- Mobile & tablet portrait -->
<div
  *ngIf="(isMobile || isTabletPortrait) && verticalMenuOpen"
  class="fixed z-50 flex h-full w-full flex-col gap-10 bg-brand-blue-800 p-small"
>
  <div class="flex flex-row items-center justify-between bg-brand-blue-800">
    <a
      [href]="'https://www.myreishauer.com/#/home'"
      class="flex h-12 w-fit items-center"
    >
      <img
        class="aspect-auto h-10"
        src="/assets/icons/Reishauer_Icons-LogoWhite.svg"
        alt="Logo"
      />
    </a>
    <button (click)="toggleVerticalMenu()" type="button" class="p-2">
      <span class="sr-only">Close sidebar</span>
      <img
        src="/assets/icons/Reishauer_Icons-Close.svg"
        alt="Close"
        class="aspect-square h-8"
      />
    </button>
  </div>

  <div
    class="flex flex-col gap-4 px-10 font-brand-regular text-[17px] text-white"
  >
    <a
      (click)="toggleVerticalMenu()"
      [routerLink]="['/organizations']"
      routerLinkActive="bg-white text-brand-blue-550"
      [routerLinkActiveOptions]="{ exact: true }"
      class="w-full rounded-md border border-white bg-none px-4 py-4"
    >
      {{ "verticalMenu.controlRoomView" | translate }}
    </a>
    <a
      (click)="toggleVerticalMenu()"
      [routerLink]="['organizations']"
      [queryParams]="{ filter: 'detailed' }"
      routerLinkActive="bg-white text-brand-blue-550"
      class="w-full rounded-md border border-white bg-none px-4 py-4"
    >
      {{ "verticalMenu.detailedView" | translate }}
    </a>
    <a
      (click)="toggleVerticalMenu()"
      [routerLink]="['organizations']"
      [queryParams]="{ filter: 'expiring' }"
      routerLinkActive="bg-white text-brand-blue-550"
      class="w-full rounded-md border border-white bg-none px-4 py-4"
    >
      {{ "verticalMenu.expiringView" | translate }}
    </a>
    <a
      (click)="toggleVerticalMenu()"
      [routerLink]="['organizations']"
      [queryParams]="{ filter: 'expired' }"
      routerLinkActive="bg-white text-brand-blue-550"
      class="w-full rounded-md border border-white bg-none px-4 py-4"
    >
      {{ "verticalMenu.soonExpiringView" | translate }}
    </a>
  </div>
</div>
