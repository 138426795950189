import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { ApplicationService } from '../../services/application/application.service';
import {
  ActionTypes,
  AddApplicationAction,
  AddApplicationFailureAction,
  AddApplicationSuccessAction,
  AddClusterAction,
  AddClusterFailureAction,
  AddClusterSuccessAction,
  AddFeatureAction,
  AddFeatureFailureAction,
  AddFeatureSuccessAction,
  AddRoleAction,
  AddRoleFailureAction,
  AddRoleSuccessAction,
  AddSubFeatureAction,
  AddSubFeatureFailureAction,
  AddSubFeatureSuccessAction,
  DeleteApplicationAction,
  DeleteApplicationFailureAction,
  DeleteApplicationSuccessAction,
  DeleteClusterAction,
  DeleteClusterFailureAction,
  DeleteClusterSuccessAction,
  DeleteFeatureAction,
  DeleteFeatureFailureAction,
  DeleteFeatureSuccessAction,
  DeleteRoleAction,
  DeleteRoleFailureAction,
  DeleteRoleSuccessAction,
  DeleteSubFeatureAction,
  DeleteSubFeatureFailureAction,
  DeleteSubFeatureSuccessAction,
  LoadApplicationsRolesAction,
  LoadApplicationsRolesFailureAction,
  LoadApplicationsRolesSuccessAction,
  LoadClustersAction,
  LoadClustersFailureAction,
  LoadClustersSuccessAction,
  LoadFeaturesByApplicationIdAction,
  LoadFeaturesByApplicationIdFailureAction,
  LoadFeaturesByApplicationIdSuccessAction,
  LoadRolesByApplicationIdAction,
  LoadRolesByApplicationIdFailureAction,
  LoadRolesByApplicationIdSuccessAction,
  SaveApplicationAction,
  SaveApplicationFailureAction,
  SaveApplicationSuccessAction,
  SaveClusterAction,
  SaveClusterFailureAction,
  SaveClusterSuccessAction,
  SaveFeatureAction,
  SaveFeatureFailureAction,
  SaveFeatureSuccessAction,
  SaveRoleAction,
  SaveRoleFailureAction,
  SaveRoleSuccessAction,
  SaveSubFeatureAction,
  SaveSubFeatureFailureAction,
  SaveSubFeatureSuccessAction,
} from './actions';

@Injectable()
export class ApplicationStoreEffects {
  constructor(
    private applicationService: ApplicationService,
    private actions$: Actions,
    private router: Router,
  ) {}

  // Cluster

  loadClustersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadClustersAction>(ActionTypes.LOAD_CLUSTERS),
      exhaustMap(() =>
        this.applicationService.GetClusters().pipe(
          map((items) => new LoadClustersSuccessAction(items)),
          catchError((error) =>
            observableOf(new LoadClustersFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddClusterAction>(ActionTypes.ADD_CLUSTER),
      exhaustMap((action) =>
        this.applicationService.AddCluster(action.clusterName).pipe(
          map((item) => new AddClusterSuccessAction(item)),
          catchError((error) =>
            observableOf(new AddClusterFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  saveClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveClusterAction>(ActionTypes.SAVE_CLUSTER),
      exhaustMap((action) =>
        this.applicationService.SaveCluster(action.cluster).pipe(
          map((item) => new SaveClusterSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveClusterFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  deleteClusterEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteClusterAction>(ActionTypes.DELETE_CLUSTER),
      exhaustMap((action) =>
        this.applicationService.DeleteCluster(action.clusterId).pipe(
          exhaustMap(() => [new DeleteClusterSuccessAction(action.clusterId)]),
          catchError((error) =>
            observableOf(new DeleteClusterFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  // Applications

  addApplicationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddApplicationAction>(ActionTypes.ADD_APPLICATION),
      exhaustMap((action) =>
        this.applicationService
          .AddApplication(action.clusterId, action.name)
          .pipe(
            map((item) => new AddApplicationSuccessAction(item)),
            catchError((error) =>
              observableOf(new AddApplicationFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  saveApplicationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveApplicationAction>(ActionTypes.SAVE_APPLICATION),
      exhaustMap((action) =>
        this.applicationService
          .SaveApplication(action.applicationId, action.clusterId, action.name)
          .pipe(
            map(
              (item) =>
                new SaveApplicationSuccessAction({
                  id: item.id,
                  clusterId: item.applicationClusterId,
                  name: item.name,
                }),
            ),
            catchError((error) =>
              observableOf(
                new SaveApplicationFailureAction(error.error.detail),
              ),
            ),
          ),
      ),
    ),
  );

  saveApplicationSuccessEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveApplicationSuccessAction>(
        ActionTypes.SAVE_APPLICATION_SUCCESS,
      ),
      switchMap((action) => [
        new LoadClustersAction(),
        new LoadFeaturesByApplicationIdAction(action.application.id),
        new LoadRolesByApplicationIdAction(action.application.id),
      ]),
    ),
  );

  deleteApplicationEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteApplicationAction>(ActionTypes.DELETE_APPLICATION),
      exhaustMap((action) =>
        this.applicationService.DeleteApplication(action.applicationId).pipe(
          exhaustMap(() => {
            this.router.navigate(['/applications']);
            return [
              new DeleteApplicationSuccessAction(
                action.clusterId,
                action.applicationId,
              ),
            ];
          }),
          catchError((error) =>
            observableOf(
              new DeleteApplicationFailureAction(error.error.detail),
            ),
          ),
        ),
      ),
    ),
  );

  // Roles

  loadAppicationsRolesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadApplicationsRolesAction>(ActionTypes.LOAD_APPLICATIONS_ROLES),
      exhaustMap((action) =>
        this.applicationService
          .GetApplicationsRoles(action.organizationId)
          .pipe(
            map((items) => new LoadApplicationsRolesSuccessAction(items)),
            catchError((error) =>
              observableOf(
                new LoadApplicationsRolesFailureAction(error.error.detail),
              ),
            ),
          ),
      ),
    ),
  );

  loadRolesByApplicationIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadRolesByApplicationIdAction>(
        ActionTypes.LOAD_ROLES_BY_APPLICATION_ID,
      ),
      exhaustMap((action) =>
        this.applicationService
          .GetRolesByApplicationId(action.applicationId)
          .pipe(
            map((items) => new LoadRolesByApplicationIdSuccessAction(items)),
            catchError((error) =>
              observableOf(
                new LoadRolesByApplicationIdFailureAction(error.error.detail),
              ),
            ),
          ),
      ),
    ),
  );

  addRoleEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddRoleAction>(ActionTypes.ADD_ROLE),
      exhaustMap((action) =>
        this.applicationService.AddRole(action.applicationId, action.role).pipe(
          map((item) => new AddRoleSuccessAction(item)),
          catchError((error) =>
            observableOf(new AddRoleFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  saveRoleEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveRoleAction>(ActionTypes.SAVE_ROLE),
      exhaustMap((action) =>
        this.applicationService.SaveRole(action.role).pipe(
          map((item) => new SaveRoleSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveRoleFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  deleteRoleEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteRoleAction>(ActionTypes.DELETE_ROLE),
      exhaustMap((action) =>
        this.applicationService.DeleteRole(action.roleId).pipe(
          exhaustMap(() => [new DeleteRoleSuccessAction(action.roleId)]),
          catchError((error) =>
            observableOf(new DeleteRoleFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  // Features

  loadFeaturesByApplicationIdEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadFeaturesByApplicationIdAction>(
        ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID,
      ),
      exhaustMap((action) =>
        this.applicationService
          .GetFeatureByApplicationId(action.applicationId)
          .pipe(
            map((items) => new LoadFeaturesByApplicationIdSuccessAction(items)),
            catchError((error) =>
              observableOf(
                new LoadFeaturesByApplicationIdFailureAction(
                  error.error.detail,
                ),
              ),
            ),
          ),
      ),
    ),
  );

  addFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddFeatureAction>(ActionTypes.ADD_FEATURE),
      exhaustMap((action) =>
        this.applicationService
          .AddFeature(action.applicationId, action.featureName)
          .pipe(
            map((item) => new AddFeatureSuccessAction(item)),
            catchError((error) =>
              observableOf(new AddFeatureFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  saveFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveFeatureAction>(ActionTypes.SAVE_FEATURE),
      exhaustMap((action) =>
        this.applicationService.SaveFeature(action.feature).pipe(
          map((item) => new SaveFeatureSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveFeatureFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  deleteFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteFeatureAction>(ActionTypes.DELETE_FEATURE),
      exhaustMap((action) =>
        this.applicationService.DeleteFeature(action.featureId).pipe(
          map(() => new DeleteFeatureSuccessAction(action.featureId)),
          catchError((error) =>
            observableOf(new DeleteFeatureFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  addSubFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddSubFeatureAction>(ActionTypes.ADD_SUB_FEATURE),
      exhaustMap((action) =>
        this.applicationService
          .AddSubFeature(
            action.applicationId,
            action.featureId,
            action.subFeatureName,
          )
          .pipe(
            map((item) => new AddSubFeatureSuccessAction(item)),
            catchError((error) =>
              observableOf(new AddSubFeatureFailureAction(error.error.detail)),
            ),
          ),
      ),
    ),
  );

  saveSubFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SaveSubFeatureAction>(ActionTypes.SAVE_SUB_FEATURE),
      exhaustMap((action) =>
        this.applicationService.SaveSubFeature(action.subFeature).pipe(
          map((item) => new SaveSubFeatureSuccessAction(item)),
          catchError((error) =>
            observableOf(new SaveSubFeatureFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  deleteSubFeatureEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSubFeatureAction>(ActionTypes.DELETE_SUB_FEATURE),
      exhaustMap((action) =>
        this.applicationService.DeleteSubFeature(action.subFeatureId).pipe(
          map(
            () =>
              new DeleteSubFeatureSuccessAction(
                action.featureId,
                action.subFeatureId,
              ),
          ),
          catchError((error) =>
            observableOf(new DeleteSubFeatureFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );
}
