<!-- Web & tablet landscape -->
<header
  *ngIf="isWeb || isTabletLandscape"
  class="flex h-24 items-center justify-between px-big py-3"
>
  <div>
    <h1
      class="mb-1 font-brand-thin text-[34px] uppercase leading-[34px] text-brand-blue-600"
    >
      ADMIN-CENTER
      <span
        *ngIf="breadcrumbs && breadcrumbs.length > 0"
        class="text-[#7a93cb]"
      >
        | {{ breadcrumbs[0].name | translate }}</span
      >
    </h1>
    <rh-admincenter-breadcrumb
      *ngIf="breadcrumbs && breadcrumbs.length > 0"
      [breadcrumbs]="breadcrumbs"
    />
  </div>

  <div class="flex items-center gap-2">
    <!-- language dropdown -->
    <rh-admincenter-language-dropdown-desktop
      [languages]="[
        {
          name: 'English',
          code: 'en'
        },
        {
          name: 'Deutsch',
          code: 'de'
        }
      ]"
      [selectedLanguageKey]="selectedLanguage"
      (languageChanged)="changeLanguage($event.code)"
    ></rh-admincenter-language-dropdown-desktop>

    <!-- user dropdown -->
    <div
      *ngIf="user"
      [ngClass]="
        showUserDropdown
          ? 'rounded-t-md bg-brand-blue-550 text-white'
          : 'text-brand-blue-400'
      "
      class="relative z-10 flex h-10 items-center p-2 text-[13px]"
    >
      <button
        type="button"
        class="flex w-full grow items-center gap-2"
        (click)="toggleUserDropdown()"
      >
        <img
          src="/assets/icons/Reishauer_Icons-User.svg"
          alt="User"
          class="h-5 w-5"
        />

        <span class="whitespace-nowrap font-brand-regular text-[13px]">
          {{ user.name }}
        </span>
        <img
          *ngIf="showUserDropdown"
          src="/assets/icons/Reishauer_Icons_Close_White.svg"
          alt="Close"
          class="ml-2 h-4 w-4"
        />
      </button>

      <div
        *ngIf="showUserDropdown"
        class="absolute right-0 top-10 flex h-fit w-full origin-top-right items-center rounded-b-md bg-brand-blue-550 text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
      >
        <a
          routerLink=""
          (click)="logout()"
          class="h-10 w-full cursor-pointer items-center rounded-b-md px-4 py-2 text-white hover:bg-brand-blue-510"
        >
          {{ "header.signOut" | translate }}
        </a>
      </div>
    </div>
  </div>
</header>

<!-- Mobile & Tablet portrait -->
<header
  *ngIf="isMobile || isTabletPortrait"
  class="flex w-full flex-row justify-between p-small"
>
  <div
    [ngClass]="{
      'flex-row': isMobileLandscape,
      'flex-col': isMobilePortrait || isTabletPortrait
    }"
    class="flex gap-2"
  >
    <a
      [href]="
        breadcrumbs && breadcrumbs.length > 1
          ? '#/'
          : 'https://www.myreishauer.com/#/home'
      "
      class="flex h-12 w-fit items-center"
    >
      <img class="aspect-auto h-10" src="/assets/logo.svg" alt="Logo" />
    </a>
    <div
      class="font-brand-thin text-[25px] uppercase leading-[25px] text-brand-blue-600"
      [ngClass]="{
        'flex items-center gap-1': isMobileLandscape
      }"
    >
      <p>ADMIN CENTER</p>
      <span
        *ngIf="breadcrumbs && breadcrumbs.length > 0"
        [ngClass]="{
          'text-[#7a93cb]': isMobileLandscape
        }"
        class="flex items-center gap-1"
      >
        <span *ngIf="isMobileLandscape">|</span>
        <p>{{ breadcrumbs[0].name | translate }}</p>
      </span>
    </div>
  </div>

  <div class="flex flex-col items-end gap-2">
    <div class="flex flex-row gap-2 rounded-full bg-[#2c4476] p-0.5">
      <button
        (click)="toggleUserMenu()"
        type="button"
        class="aspect-square h-10 rounded-full border border-[#808fad] p-1.5 hover:bg-[#808fad]"
      >
        <img
          src="/assets/icons/Reishauer_Icons-User-White.svg"
          alt="Vertical menu"
          class="aspect-square h-full"
        />
      </button>
      <button
        (click)="toggleSidebar()"
        type="button"
        class="aspect-square h-10 rounded-full border border-[#808fad] p-1.5 hover:bg-[#808fad]"
      >
        <img
          src="/assets/icons/Reishauer_Icons-Menu.svg"
          alt="Menu"
          class="aspect-square h-full"
        />
      </button>
    </div>
    <button
      *ngIf="enableVerticalMenu"
      (click)="toggleVerticalMenu()"
      type="button"
      class="mr-0.5 aspect-square h-10 rounded-full border border-brand-blue-800 p-1.5"
    >
      <img
        src="/assets/icons/Reishauer_Icons-Filter.svg"
        alt="User menu"
        class="aspect-square h-full"
      />
    </button>
  </div>
</header>
