import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { LoggedUserPermissionsMisc } from './core/models/loggedUserPermissionsMisc.model';
import { State } from './core/store/state';

@Component({
  selector: 'rh-admincenter-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  loggedUserPermissionsMisc$?: Observable<LoggedUserPermissionsMisc>;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.screenWidth = window.innerWidth;
  }

  isLoading$!: Observable<boolean>;
  breadcrumbs$!: Observable<
    {
      name: string;
      url?: string | undefined;
    }[]
  >;

  screenWidth = 0;
  sidebarOpen = false;
  userMenuOpen = false;
  verticalMenuOpen = false;
  backgroundColor = 'bg-white';

  enableVerticalMenu = true;

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        }),
    );

    this.isLoading$ = this.store$.select((state) => {
      return state.Auth.isLoading as boolean;
    });

    this.loggedUserPermissionsMisc$ = this.store$.select((state) => {
      return state.Auth.permissions as LoggedUserPermissionsMisc;
    });

    this.breadcrumbs$ = this.store$.select((state) => {
      return state.Breadcrumb.breadcrumbs as
        | {
            name: string;
            url?: string | undefined;
          }[]
        | [];
    });

    this.subscriptions.add(
      this.breadcrumbs$.subscribe(
        (
          data: {
            name: string;
            url?: string | undefined;
          }[],
        ) => {
          if (data && data.length === 1 && data[0].url === '/organizations') {
            this.backgroundColor = 'bg-white';
            this.enableVerticalMenu = true;
            this.verticalMenuOpen = false;
          } else {
            this.backgroundColor = 'bg-brand-gray-200';
            this.enableVerticalMenu = false;
            this.verticalMenuOpen = false;
          }
        },
      ),
    );
  }

  toggleBackgroundEvent(): void {
    this.backgroundColor = 'bg-gray-500';
  }

  toggleSidebarEvent(): void {
    this.sidebarOpen = !this.sidebarOpen;
  }

  toggleUserMenuEvent(): void {
    this.userMenuOpen = !this.userMenuOpen;
  }

  toggleVerticalMenuEvent(): void {
    this.verticalMenuOpen = !this.verticalMenuOpen;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
