import { Application } from '../../models/application.model';
import { Department } from '../../models/department.model';
import { Feature } from '../../models/feature.model';
import { SubFeature } from '../../models/subFeature.model';
import { UnitLicences } from '../../models/unitLicences.model';
import { User } from '../../models/user.model';
import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_ORGANIZATIONS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        organizations: undefined,
      };
    }
    case ActionTypes.LOAD_ORGANIZATIONS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organizations: action.organizations,
      };
    }
    case ActionTypes.LOAD_ORGANIZATIONS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.LOAD_ORGANIZATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        organization: undefined,
      };
    }
    case ActionTypes.LOAD_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organization: action.organization,
      };
    }
    case ActionTypes.LOAD_ORGANIZATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.ADD_ORGANIZATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        success: 'success.addOrganization',
      };
    }
    case ActionTypes.ADD_ORGANIZATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_ORGANIZATION: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        organizations: state.organizations?.map((organization) => {
          if (organization.id !== action.organization.id) {
            return organization;
          }
          return {
            ...organization,
            name: action.organization.name,
          };
        }),
        success: 'success.saveOrganization',
      };
    }
    case ActionTypes.SAVE_ORGANIZATION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.LOAD_UNIT_USERS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        unitsUsers: undefined,
      };
    }
    case ActionTypes.LOAD_UNIT_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        unitsUsers: action.unitUsers,
      };
    }
    case ActionTypes.LOAD_UNIT_USERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        unitsUsers: undefined,
      };
    }
    case ActionTypes.LOAD_UNITS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_UNITS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        units: action.units,
      };
    }
    case ActionTypes.LOAD_UNITS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        units: [],
      };
    }
    case ActionTypes.ADD_UNIT: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_UNIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.addUnit',
      };
    }
    case ActionTypes.ADD_UNIT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_UNIT: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_UNIT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        units: state.units?.map((unit) => {
          if (unit.id !== action.unit.id) {
            return unit;
          }
          return {
            ...unit,
            name: action.unit.name,
          };
        }),
        success: 'success.saveUnit',
      };
    }
    case ActionTypes.SAVE_UNIT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.ADD_MEMBER: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_MEMBER_SUCCESS: {
      return {
        ...state,
        success: 'success.addUser',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.user.unitId) {
            return {
              ...unitUser,
              userCount: {
                ...unitUser.userCount,
                total: action.user.userCount,
              },
              users: [
                ...unitUser.users,
                {
                  id: action.user.id,
                  email: action.user.email,
                  givenName: action.user.givenName,
                  familyName: action.user.familyName,
                  department: {
                    id: action.user.department.id,
                    name: action.user.department.name,
                  },
                  ciamAdmin: false,
                  isBlocked: false,
                },
              ],
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.ADD_MEMBER_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_MEMBER: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_MEMBER_SUCCESS: {
      return {
        ...state,
        success: 'success.saveUser',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.user.unitId) {
            return {
              ...unitUser,
              userCount: {
                ...unitUser.userCount,
                total: action.user.userCount,
              },
              users: unitUser.users.map((user) => {
                if (user.id === action.user.id) {
                  return {
                    ...user,
                    email: action.user.email,
                    givenName: action.user.givenName,
                    familyName: action.user.familyName,
                    department: {
                      id: action.user.department.id,
                      name: action.user.department.name,
                    },
                  };
                }
                return user;
              }),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.SAVE_MEMBER_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_MEMBER: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_MEMBER_SUCCESS: {
      return {
        ...state,
        success: 'success.deleteUser',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.unitId) {
            return {
              ...unitUser,
              userCount: {
                ...unitUser.userCount,
                total: unitUser.userCount.total - 1,
              },
              users: unitUser.users.filter(
                (user) => user.id !== action.memberId,
              ),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.DELETE_MEMBER_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.SAVE_MEMBER_ROLES: {
      return {
        ...state,
        isLoading: false,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.SAVE_MEMBER_ROLES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        success: 'success.saveRoles',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.unitId) {
            return {
              ...unitUser,
              users: unitUser.users.map((user) => {
                if (user.id === action.userId) {
                  const roles = user.roles?.filter(
                    (role) => role.applicationId !== action.applicationId,
                  );
                  return {
                    ...user,
                    roles: [...(roles || []), ...action.roles],
                  } as User;
                }
                return user;
              }),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.SAVE_MEMBER_ROLES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.unitId) {
            return {
              ...unitUser,
              users: unitUser.users.map((user) => {
                if (user.id === action.userId) {
                  return {
                    ...user,
                    roles: [...(user.roles || [])],
                  } as User;
                }
                return user;
              }),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.ADD_LICENCE: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_LICENCE_SUCCESS: {
      return {
        ...state,
        success: 'success.addLicence',
        units: updateUnitAfterLicenceAddition(state.units, action.licence),
      };
    }
    case ActionTypes.ADD_LICENCE_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.EDIT_LICENCES: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.EDIT_LICENCES_SUCCESS: {
      return {
        ...state,
        success: 'success.saveLicences',
        units: state.units?.map((unit) => {
          if (unit.id === action.licence.unitId) {
            return {
              ...unit,
              licences: unit.licences?.map((licence) => {
                if (licence.id === action.licence.clusterId) {
                  return {
                    ...licence,
                    applications: licence.applications?.map((application) => {
                      if (application.id === action.licence.applicationId) {
                        return {
                          ...application,
                          validTo: action.licence.validTo,
                          status: action.licence.status,
                          isInvoiceSent: action.licence.isInvoiceSent,
                        };
                      }
                      return application;
                    }),
                  };
                }
                return licence;
              }),
            };
          }
          return unit;
        }),
      };
    }
    case ActionTypes.EDIT_LICENCES_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_LICENCE: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_LICENCE_SUCCESS: {
      return {
        ...state,
        success: 'success.deleteLicence',
        units: state.units?.map((unit) => {
          return {
            ...unit,
            licences: unit.licences.map((licence) => {
              let applications = licence.applications.map((application) => {
                if (application.id !== action.applicationId) {
                  return application;
                }

                if (
                  application.features.length > 0 &&
                  application.features[0].subFeatures.length > 0
                ) {
                  let features = application.features.map((feature) => {
                    return {
                      ...feature,
                      subFeatures: feature.subFeatures.filter(
                        (subFeature) =>
                          subFeature.licenceId !== action.licenceId,
                      ),
                    };
                  });

                  features = features.filter(
                    (feature) => feature.subFeatures.length > 0,
                  );
                  return {
                    ...application,
                    features: features,
                  } as Application;
                }

                return {
                  ...application,
                  features: application.features.filter(
                    (feature) => feature.licenceId !== action.licenceId,
                  ),
                } as Application;
              });

              applications = applications.filter(
                (application) => application.features.length > 0,
              );

              return {
                ...licence,
                applications: applications,
              };
            }),
          };
        }),
      };
    }
    case ActionTypes.DELETE_LICENCE_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.LOAD_DEPARTMENTS: {
      return {
        ...state,
        error: undefined,
        departments: undefined,
      };
    }
    case ActionTypes.LOAD_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        error: undefined,
        departments: action.departments,
      };
    }
    case ActionTypes.LOAD_DEPARTMENTS_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.ADD_DEPARTMENT: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.ADD_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        success: 'success.addDepartment',
        departments: state.departments
          ? (state.departments.concat(action.department) as Department[])
          : [action.department],
      };
    }
    case ActionTypes.ADD_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.DELETE_DEPARTMENT: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.DELETE_DEPARTMENT_SUCCESS: {
      return {
        ...state,
        success: 'success.deleteDepartment',
      };
    }
    case ActionTypes.DELETE_DEPARTMENT_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.BLOCK_USER_ACTION: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.BLOCK_USER_ACTION_SUCCESS: {
      return {
        ...state,
        success: 'success.blockUser',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.unitId) {
            return {
              ...unitUser,
              users: unitUser.users.map((user) => {
                if (user.id === action.userId) {
                  return {
                    ...user,
                    isBlocked: true,
                  };
                }
                return user;
              }),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.BLOCK_USER_ACTION_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.UNBLOCK_USER_ACTION: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.UNBLOCK_USER_ACTION_SUCCESS: {
      return {
        ...state,
        success: 'success.unblockUser',
        unitsUsers: state.unitsUsers?.map((unitUser) => {
          if (unitUser.unitId === action.unitId) {
            return {
              ...unitUser,
              users: unitUser.users.map((user) => {
                if (user.id === action.userId) {
                  return {
                    ...user,
                    isBlocked: false,
                  };
                }
                return user;
              }),
            };
          }
          return unitUser;
        }),
      };
    }
    case ActionTypes.UNBLOCK_USER_ACTION_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.CHANGE_PASSWORD_ACTION: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.CHANGE_PASSWORD_ACTION_SUCCESS: {
      return {
        ...state,
        success: 'success.changePassword',
      };
    }
    case ActionTypes.CHANGE_PASSWORD_ACTION_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.RESET_2FA_ACTION: {
      return {
        ...state,
        error: undefined,
        success: undefined,
      };
    }
    case ActionTypes.RESET_2FA_ACTION_SUCCESS: {
      return {
        ...state,
        success: 'success.reset2fa',
      };
    }
    case ActionTypes.RESET_2FA_ACTION_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.RESET_ORGANIZATION_STATE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

function updateUnitAfterLicenceAddition(
  unitLicences: UnitLicences[] | undefined,
  action: {
    id: number;
    unitId: number;
    clusterId: number;
    applicationId: number;
    featureId: number;
    subFeatureId: number | null;
    isFeatureLicence: boolean;
    validTo: string;
    status: string;
  },
) {
  return unitLicences?.map((unit) => {
    if (unit.id === action.unitId) {
      let licence = unit.licences.find(
        (licence) => licence.id === action.clusterId,
      );
      if (!licence) {
        licence = {
          id: action.clusterId,
          name: '',
          applications: [
            {
              id: action.applicationId,
              validTo: action.validTo,
              status: action.status,
              features: action.isFeatureLicence
                ? [
                    {
                      id: action.featureId,
                      licenceId: action.id,
                      subFeatures: [],
                    },
                  ]
                : [
                    {
                      id: action.featureId,
                      subFeatures: [
                        {
                          id: action.subFeatureId,
                          licenceId: action.id,
                        } as SubFeature,
                      ],
                    } as Feature,
                  ],
            },
          ] as Application[],
        };
        return {
          ...unit,
          licences: [...unit.licences, licence],
        };
      }

      return {
        ...unit,
        licences: unit.licences.map((licence) => {
          if (licence.id === action.clusterId) {
            let application = licence.applications.find(
              (application) => application.id === action.applicationId,
            );

            if (!application) {
              application = {
                id: action.applicationId,
                validTo: action.validTo,
                status: action.status,
                features: action.isFeatureLicence
                  ? [
                      {
                        id: action.featureId,
                        licenceId: action.id,
                        subFeatures: [],
                      },
                    ]
                  : [
                      {
                        id: action.featureId,
                        subFeatures: [
                          {
                            id: action.subFeatureId,
                            licenceId: action.id,
                          } as SubFeature,
                        ],
                      } as Feature,
                    ],
              } as Application;
              return {
                ...licence,
                applications: [...licence.applications, application],
              };
            }

            return {
              ...licence,
              applications: licence.applications.map((application) => {
                if (application.id === action.applicationId) {
                  if (action.isFeatureLicence) {
                    return {
                      ...application,
                      validTo: action.validTo,
                      status: action.status,
                      features: [
                        ...application.features,
                        {
                          id: action.featureId,
                          licenceId: action.id,
                          subFeatures: [] as SubFeature[],
                        } as Feature,
                      ],
                    } as Application;
                  }

                  const feature = application.features.find(
                    (feature) => feature.id === action.featureId,
                  );

                  if (!feature) {
                    return {
                      ...application,
                      validTo: action.validTo,
                      status: action.status,
                      features: [
                        ...application.features,
                        {
                          id: action.featureId,
                          subFeatures: [
                            {
                              id: action.subFeatureId,
                              licenceId: action.id,
                            } as SubFeature,
                          ],
                        } as Feature,
                      ],
                    };
                  }

                  return {
                    ...application,
                    validTo: action.validTo,
                    features: application.features.map((feature) => {
                      if (feature.id === action.featureId) {
                        return {
                          ...feature,
                          subFeatures: [
                            ...feature.subFeatures,
                            {
                              id: action.subFeatureId,
                              licenceId: action.id,
                            } as SubFeature,
                          ],
                        } as Feature;
                      }
                      return feature;
                    }),
                  };
                }
                return application;
              }),
            };
          }
          return licence;
        }),
      } as UnitLicences;
    }
    return unit;
  });
}
