<div
  *ngIf="isLandscape || isPortrait"
  class="fixed z-50 flex h-full w-full flex-col bg-brand-blue-800"
>
  <div class="flex flex-row items-center justify-between p-small">
    <div class="flex h-12 items-center">
      <img
        src="/assets/icons/Reishauer_Icons-LogoWhite.svg"
        alt="Logo"
        class="h-10"
      />
    </div>
    <button (click)="toggleUserMenu()" type="button" class="p-2">
      <img
        src="/assets/icons/Reishauer_Icons-Close.svg"
        alt="Close"
        class="aspect-square h-8"
      />
    </button>
  </div>

  <div
    [ngClass]="isLandscape ? 'flex-row gap-16' : 'flex-col items-center gap-10'"
    class="flex justify-center p-big"
  >
    <div class="flex w-full max-w-[250px] flex-col gap-3">
      <h1 class="uppercase text-[#808fad]">
        {{ "userMenu.account" | translate }}
      </h1>
      <p class="text-white">{{ user?.name }}</p>
      <button
        class="h-10 w-full rounded-md bg-white uppercase text-[#2c4476]"
        (click)="logout()"
      >
        {{ "userMenu.logout" | translate }}
      </button>
    </div>
    <div class="flex w-full max-w-[250px] flex-col gap-3">
      <h1 class="uppercase text-[#808fad]">
        {{ "userMenu.language" | translate }}
      </h1>
      <rh-admincenter-language-dropdown-mobile
        [languages]="[
          {
            name: 'English',
            code: 'en'
          },
          {
            name: 'Deutsch',
            code: 'de'
          }
        ]"
        [selectedLanguageKey]="selectedLanguage"
        (languageChanged)="changeLanguage($event.code)"
      ></rh-admincenter-language-dropdown-mobile>
    </div>
  </div>
</div>
