import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Auth0User } from 'src/app/core/models/auth0User.model';
import {
  ChangeLanguageAction,
  LogOutUserAction,
} from 'src/app/core/store/auth/actions';
import { State } from 'src/app/core/store/state';

@Component({
  selector: 'rh-admincenter-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Input() enableVerticalMenu!: boolean;
  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Output() toggleVerticalMenuEvent = new EventEmitter<void>();
  @Output() toggleUserMenuEvent = new EventEmitter<void>();

  showUserDropdown = false;
  showLanguageDropdown = false;
  user$!: Observable<Auth0User>;
  user?: Auth0User;
  selectedLanguage = 'en';
  languages = ['en', 'de'];
  breadcrumbs$!: Observable<{ name: string; url?: string }[]>;
  breadcrumbs?: { name: string; url?: string }[];

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        }),
    );

    this.user$ = this.store$.select((state) => {
      return state.Auth.user as Auth0User;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: Auth0User) => {
        this.user = user;
        this.selectedLanguage = user?.language || 'en';
      }),
    );

    this.breadcrumbs$ = this.store$.select((state) => {
      return state.Breadcrumb.breadcrumbs as { name: string; url?: string }[];
    });

    this.subscriptions.add(
      this.breadcrumbs$?.subscribe((data: { name: string; url?: string }[]) => {
        this.breadcrumbs = data;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  toggleSidebar(): void {
    this.toggleSidebarEvent.emit();
  }

  toggleVerticalMenu(): void {
    this.toggleVerticalMenuEvent.emit();
  }

  toggleUserMenu(): void {
    this.toggleUserMenuEvent.emit();
  }

  toggleUserDropdown(): void {
    this.showUserDropdown = !this.showUserDropdown;
  }

  toggleLanguageDropdown(): void {
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  changeLanguage(lang: string): void {
    this.store$.dispatch(new ChangeLanguageAction(lang));
    this.toggleLanguageDropdown();
  }

  logout(): void {
    this.store$.dispatch(new LogOutUserAction());
  }
}
