import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {
  LoadLoggedUserPermissionsMiscAction,
  LoadUserAction,
} from './core/store/auth/actions';
import { State } from './core/store/state';
import { HeaderComponent } from './layout/header/header.component';
import { LanguageDropdownDesktopComponent } from './layout/language-dropdown-desktop/language-dropdown-desktop.component';
import { LanguageDropdownMobileComponent } from './layout/language-dropdown-mobile/language-dropdown-mobile.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { UserMenuComponent } from './layout/user-menu/user-menu.component';
import { VerticalMenuComponent } from './layout/vertical-menu/vertical-menu.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    VerticalMenuComponent,
    UserMenuComponent,
    LanguageDropdownDesktopComponent,
    LanguageDropdownMobileComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: environment.redirectUri,
        audience: environment.audience,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.backendApiUrl}/api/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.audience,
              },
            },
          },
        ],
      },
    }),
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (store: Store<State>) => {
        return () => {
          console.log('APP_INITIALIZER');
          store.dispatch(new LoadUserAction());
          store.dispatch(new LoadLoggedUserPermissionsMiscAction());
        };
      },
      multi: true,
      deps: [Store],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    provideAnimations(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
