import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';

@NgModule({
  declarations: [
    LoadingComponent,
    SnackbarComponent,
    DeleteDialogComponent,
    BreadcrumbComponent,
    MultiSelectComponent,
  ],
  imports: [CommonModule, FormsModule, RouterModule, TranslateModule],
  exports: [
    FormsModule,
    LoadingComponent,
    SnackbarComponent,
    BreadcrumbComponent,
    TranslateModule,
    MultiSelectComponent,
  ],
})
export class SharedModule {}
