import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { canSeeApplicationGuard } from './core/guards/can-see-application.guard';

const routes: Routes = [
  {
    path: 'applications',
    loadChildren: () =>
      import('./features/applications/applications.module').then(
        (m) => m.ApplicationsModule,
      ),
    canActivate: [AuthGuard, canSeeApplicationGuard],
  },
  {
    path: 'organizations',
    loadChildren: () =>
      import('./features/organizations/organizations.module').then(
        (m) => m.OrganizationsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'organizations',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
