<nav class="flex">
  <ol class="flex items-center gap-1">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index">
      <div
        class="flex items-center gap-1 text-[13px] leading-[13px] text-brand-gray"
      >
        <p *ngIf="i > 0">&#62;</p>
        <a [routerLink]="[breadcrumb.url]" routerLinkActive="">
          {{ breadcrumb.name | translate }}
        </a>
      </div>
    </li>
  </ol>
</nav>
