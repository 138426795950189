import { ApplicationStoreState } from './application';
import { AuthStoreState } from './auth';
import { BreadcrumbStoreState } from './breadcrumb';
import { OrganizationStoreState } from './organization';

export interface State {
  Auth: AuthStoreState.State;
  Organization: OrganizationStoreState.State;
  Application: ApplicationStoreState.State;
  Breadcrumb: BreadcrumbStoreState.State;
}
