<div class="relative w-full">
  <button
    role="button"
    class="flex h-[50px] w-full items-center justify-between gap-2 rounded-md border border-gray-300 p-2"
    (click)="toggleDropdown()"
  >
    <p class="text-left">{{ selectedRoleName | translate }}</p>
    <img
      src="/assets/icons/chevron-down.svg"
      width="22px"
      alt="chevron"
      [ngClass]="isToggled ? 'rotate-180' : 'rotate-0'"
    />
  </button>
  <div
    *ngIf="isToggled"
    class="custom-scrollbar absolute z-50 mt-1 flex max-h-60 w-full flex-col divide-y divide-gray-100 overflow-auto rounded-md border border-gray-300 bg-white"
  >
    <label
      class="flex w-full cursor-pointer items-center justify-between gap-2 p-2 hover:bg-gray-100"
      [htmlFor]="role.id"
      *ngFor="let role of roles"
    >
      <p class="text-left">{{ role.name }}</p>
      <input
        id="{{ role.id }}"
        [type]="allowMultiSelect ? 'checkbox' : 'radio'"
        [checked]="findRole(role)"
        (click)="onRoleChange(role)"
      />
    </label>

    <div *ngIf="roles?.length === 0" class="p-2">
      <p class="text-brand-blue-200">
        {{ "rolesSelect.noRoles" | translate }}
      </p>
    </div>
  </div>
</div>
