import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationCluster } from '../../models/applicationCluster.mode';
import { ApplicationRoles } from '../../models/applicationRoles.model';
import { Cluster } from '../../models/cluster.model';
import { Feature } from '../../models/feature.model';
import { Role } from '../../models/role.model';
import { SubFeature } from '../../models/subFeature.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private http: HttpClient) {}

  // Clusters

  GetClusters(): Observable<Cluster[]> {
    return this.http
      .get<
        Cluster[]
      >(`${environment.backendApiUrl}/api/applications/with-clusters`)
      .pipe(
        map((response: Cluster[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddCluster(clusterName: string) {
    return this.http
      .post<{
        id: number;
        name: string;
      }>(`${environment.backendApiUrl}/api/applications/application-clusters`, {
        name: clusterName,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveCluster(cluster: ApplicationCluster) {
    return this.http
      .put<{
        id: number;
        name: string;
      }>(
        `${environment.backendApiUrl}/api/applications/application-clusters/${cluster.id}`,
        {
          name: cluster.name,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteCluster(clusterId: number) {
    return this.http
      .delete(
        `${environment.backendApiUrl}/api/applications/application-clusters/${clusterId}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Applications

  AddApplication(clusterId: number, name: string) {
    return this.http
      .post<{
        id: number;
        applicationClusterId: number;
        name: string;
      }>(`${environment.backendApiUrl}/api/applications`, {
        applicationClusterId: clusterId,
        name,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveApplication(applicationId: number, clusterId: number, name: string) {
    return this.http
      .put<{
        id: number;
        applicationClusterId: number;
        name: string;
      }>(`${environment.backendApiUrl}/api/applications/${applicationId}`, {
        applicationClusterId: clusterId,
        name,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteApplication(applicationId: number) {
    return this.http
      .delete(`${environment.backendApiUrl}/api/applications/${applicationId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Roles

  GetApplicationsRoles(organizationId: number): Observable<ApplicationRoles[]> {
    return this.http
      .get<
        ApplicationRoles[]
      >(`${environment.backendApiUrl}/api/applications/roles/companies/${organizationId}`)
      .pipe(
        map((response: ApplicationRoles[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetRolesByApplicationId(applicationId: number): Observable<Role[]> {
    return this.http
      .get<
        Role[]
      >(`${environment.backendApiUrl}/api/applications/${applicationId}/roles`)
      .pipe(
        map((response: Role[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddRole(applicationId: number, role: Role) {
    return this.http
      .post<Role>(
        `${environment.backendApiUrl}/api/applications/${applicationId}/roles`,
        {
          name: role.name,
          description: role.description,
          isDefault: role.isDefault,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveRole(role: Role) {
    return this.http
      .put<Role>(
        `${environment.backendApiUrl}/api/applications/roles/${role.id}`,
        {
          name: role.name,
          description: role.description,
          isDefault: role.isDefault,
        },
      )
      .pipe(
        map((response) => {
          return response as Role;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteRole(roleId: number) {
    return this.http
      .delete(`${environment.backendApiUrl}/api/applications/roles/${roleId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Features

  GetFeatureByApplicationId(applicationId: number): Observable<Feature[]> {
    return this.http
      .get<
        Feature[]
      >(`${environment.backendApiUrl}/api/applications/${applicationId}/features-with-subfeatures`)
      .pipe(
        map((response: Feature[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddFeature(applicationId: number, featureName: string) {
    return this.http
      .post<{
        id: number;
        name: string;
      }>(
        `${environment.backendApiUrl}/api/applications/${applicationId}/features`,
        {
          name: featureName,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveFeature(feature: Feature) {
    return this.http
      .put<{
        id: number;
        name: string;
        key: string;
      }>(
        `${environment.backendApiUrl}/api/applications/features/${feature.id}`,
        {
          name: feature.name,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteFeature(id: number) {
    return this.http
      .delete(`${environment.backendApiUrl}/api/applications/features/${id}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddSubFeature(
    applicationId: number,
    featureId: number,
    subFeatureName: string,
  ) {
    return this.http
      .post<{
        id: number;
        name: string;
        key: string;
        featureId: number;
      }>(
        `${environment.backendApiUrl}/api/applications/${applicationId}/features/${featureId}/subfeatures`,
        {
          name: subFeatureName,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveSubFeature(subFeature: SubFeature) {
    return this.http
      .put<{
        id: number;
        name: string;
        key: string;
        featureId: number;
      }>(
        `${environment.backendApiUrl}/api/applications/subfeatures/${subFeature.id}`,
        {
          name: subFeature.name,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteSubFeature(id: number) {
    return this.http
      .delete(`${environment.backendApiUrl}/api/applications/subfeatures/${id}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
