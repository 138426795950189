import { Component, Inject } from '@angular/core';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  constructor(
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      title: string;
      message: string;
    }
  ) {}

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
