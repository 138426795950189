import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Language } from 'src/app/core/models/language.model';

@Component({
  selector: 'rh-admincenter-language-dropdown-desktop',
  templateUrl: './language-dropdown-desktop.component.html',
  styleUrls: ['./language-dropdown-desktop.component.css'],
})
export class LanguageDropdownDesktopComponent {
  public listVisible = false;

  selectedLanguage: Language = {
    name: 'English',
    code: 'en',
  };

  showLanguageDropdown = false;

  @Input() languages: Language[] = [];
  @Output() languageChanged: EventEmitter<Language> = new EventEmitter();

  private _selectedLanguageKey = 'en';

  @Input()
  public set selectedLanguageKey(value: string) {
    this._selectedLanguageKey = value;
    this.selectedLanguage = (this.languages.find(
      (lang) => lang.code === value,
    ) || this.selectedLanguage.code) as Language;
  }

  public get selectedLanguageKey(): string {
    return (
      this.languages.find((lang) => lang.code === this._selectedLanguageKey)
        ?.code || this.selectedLanguage.code
    );
  }

  toggleLanguageDropdown(): void {
    this.showLanguageDropdown = !this.showLanguageDropdown;
  }

  changeLanguage(language: Language): void {
    this.languageChanged.emit(language);
    this.selectedLanguage = language;
    this.selectedLanguageKey = language.code;
    this.toggleLanguageDropdown();
  }

  public async toggle() {
    this.listVisible = !this.listVisible;
  }

  public async select(item: Language) {
    this.listVisible = false;

    if (this.selectedLanguageKey === item.code) {
      return;
    }

    this.selectedLanguageKey = item.code;
    this.languageChanged?.emit(item);
  }

  isSelected(item: Language): boolean {
    return this.selectedLanguageKey === item.code;
  }

  public onFocusOutEvent() {
    // We need to give the click-event precedence, otherwise it will not be fired
    setTimeout(() => {
      this.listVisible = false;
    }, 200);
  }
}
