import { Action } from '@ngrx/store';

export enum ActionTypes {
  SET_BREADCRUMBS = '[Breadcrumb] Set breadcrumb',
}

export class SetBreadcrumbsAction implements Action {
  readonly type = ActionTypes.SET_BREADCRUMBS;
  constructor(public breadcrumbs: { name: string; url?: string }[]) {}
}

export type Actions = SetBreadcrumbsAction;
