import { Action } from '@ngrx/store';
import { Department } from '../../models/department.model';
import { Organization } from '../../models/organization.model';
import { OrganizationFolded } from '../../models/organizationFolded.model';
import { Role } from '../../models/role.model';
import { UnitLicences } from '../../models/unitLicences.model';
import { UnitUsers } from '../../models/unitUsers.model';
import { User } from '../../models/user.model';

export enum ActionTypes {
  LOAD_ORGANIZATIONS = '[Organization] Load Organizations Data',
  LOAD_ORGANIZATIONS_SUCCESS = '[Organization] Load Organizations Success',
  LOAD_ORGANIZATIONS_FAILURE = '[Organization] Load Organizations Failure',
  LOAD_ORGANIZATION = '[Organization] Load Organization Data',
  LOAD_ORGANIZATION_SUCCESS = '[Organization] Load Organization Success',
  LOAD_ORGANIZATION_FAILURE = '[Organization] Load Organization Failure',
  ADD_ORGANIZATION = '[Organization] Add Organization',
  ADD_ORGANIZATION_SUCCESS = '[Organization] Add Organization Success',
  ADD_ORGANIZATION_FAILURE = '[Organization] Add Organization Failure',
  SAVE_ORGANIZATION = '[Organization] Save Organization',
  SAVE_ORGANIZATION_SUCCESS = '[Organization] Save Organization Success',
  SAVE_ORGANIZATION_FAILURE = '[Organization] Save Organization Failure',
  LOAD_UNITS = '[Organization] Load Units Data',
  LOAD_UNITS_SUCCESS = '[Organization] Load Units Success',
  LOAD_UNITS_FAILURE = '[Organization] Load Units Failure',
  ADD_UNIT = '[Organization] Add Unit',
  ADD_UNIT_SUCCESS = '[Organization] Add Unit Success',
  ADD_UNIT_FAILURE = '[Organization] Add Unit Failure',
  SAVE_UNIT = '[Organization] Save Unit',
  SAVE_UNIT_SUCCESS = '[Organization] Save Unit Success',
  SAVE_UNIT_FAILURE = '[Organization] Save Unit Failure',
  ADD_LICENCE = '[Organization] Add Licence Data',
  ADD_LICENCE_SUCCESS = '[Organization] Add Licence Success',
  ADD_LICENCE_FAILURE = '[Organization] Add Licence Failure',
  EDIT_LICENCES = '[Organization] Edit Licences Valid To',
  EDIT_LICENCES_SUCCESS = '[Organization] Edit Licences Valid To Success',
  EDIT_LICENCES_FAILURE = '[Organization] Edit Licences Valid To Failure',
  DELETE_LICENCE = '[Licence] Delete Licence',
  DELETE_LICENCE_SUCCESS = '[Licence] Delete Licence Success',
  DELETE_LICENCE_FAILURE = '[Licence] Delete Licence Failure',
  ADD_MEMBER = '[Organization] Add Member Data',
  ADD_MEMBER_SUCCESS = '[Organization] Add Member Success',
  ADD_MEMBER_FAILURE = '[Organization] Add Member Failure',
  DELETE_MEMBER = '[Organization] Delete Member Data',
  DELETE_MEMBER_SUCCESS = '[Organization] Delete Member Success',
  DELETE_MEMBER_FAILURE = '[Organization] Delete Member Failure',
  SAVE_MEMBER = '[Organization] Save Member',
  SAVE_MEMBER_SUCCESS = '[Organization] Save Member Success',
  SAVE_MEMBER_FAILURE = '[Organization] Save Member Failure',
  SAVE_MEMBER_ROLES = '[Organization] Save Member Roles',
  SAVE_MEMBER_ROLES_SUCCESS = '[Organization] Save Member Roles Success',
  SAVE_MEMBER_ROLES_FAILURE = '[Organization] Save Member Roles Failure',
  LOAD_UNIT_USERS = '[Organization] Load Unit Users',
  LOAD_UNIT_USERS_SUCCESS = '[Organization] Load Unit Users Success',
  LOAD_UNIT_USERS_FAILURE = '[Organization] Load Unit Users Failure',
  BLOCK_USER_ACTION = '[User] Block User',
  BLOCK_USER_ACTION_SUCCESS = '[User] Block User Success',
  BLOCK_USER_ACTION_FAILURE = '[User] Block User Failure',
  UNBLOCK_USER_ACTION = '[User] Unblock user',
  UNBLOCK_USER_ACTION_SUCCESS = '[User] Unblock user Success',
  UNBLOCK_USER_ACTION_FAILURE = '[User] Unblock user Failure',
  RESET_2FA_ACTION = '[User] Reset 2fa',
  RESET_2FA_ACTION_SUCCESS = '[User] Reset 2fa Success',
  RESET_2FA_ACTION_FAILURE = '[User] Reset 2fa Failure',
  CHANGE_PASSWORD_ACTION = '[User] Change Password',
  CHANGE_PASSWORD_ACTION_SUCCESS = '[User] Change Password Success',
  CHANGE_PASSWORD_ACTION_FAILURE = '[User] Change Password Failure',
  LOAD_DEPARTMENTS = '[Organization] Load Departments',
  LOAD_DEPARTMENTS_SUCCESS = '[Organization] Load Departments Success',
  LOAD_DEPARTMENTS_FAILURE = '[Organization] Load Departments Failure',
  ADD_DEPARTMENT = '[Organization] Add Department',
  ADD_DEPARTMENT_SUCCESS = '[Organization] Add Department Success',
  ADD_DEPARTMENT_FAILURE = '[Organization] Add Department Failure',
  DELETE_DEPARTMENT = '[Organization] Delete Department',
  DELETE_DEPARTMENT_SUCCESS = '[Organization] Delete Department Success',
  DELETE_DEPARTMENT_FAILURE = '[Organization] Delete Department Failure',
  RESET_ORGANIZATION_STATE = '[Organization] Reset Organization State',
}

export class LoadOrganizationsAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATIONS;
}

export class LoadOrganizationsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATIONS_SUCCESS;
  constructor(public organizations: OrganizationFolded[]) {}
}

export class LoadOrganizationsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATIONS_FAILURE;
  constructor(public error: string) {}
}

export class LoadOrganizationAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATION;
  constructor(public organizationId: number) {}
}

export class LoadOrganizationSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATION_SUCCESS;
  constructor(public organization: Organization) {}
}

export class LoadOrganizationFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ORGANIZATION_FAILURE;
  constructor(public error: string) {}
}

export class AddOrganizationAction implements Action {
  readonly type = ActionTypes.ADD_ORGANIZATION;
  constructor(public organizationName: string) {}
}

export class AddOrganizationSuccessAction implements Action {
  readonly type = ActionTypes.ADD_ORGANIZATION_SUCCESS;
}

export class AddOrganizationFailureAction implements Action {
  readonly type = ActionTypes.ADD_ORGANIZATION_FAILURE;
  constructor(public error: string) {}
}

export class SaveOrganizationAction implements Action {
  readonly type = ActionTypes.SAVE_ORGANIZATION;
  constructor(public organization: Organization) {}
}

export class SaveOrganizationSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_ORGANIZATION_SUCCESS;
  constructor(
    public organization: {
      id: number;
      name: string;
    },
  ) {}
}

export class SaveOrganizationFailureAction implements Action {
  readonly type = ActionTypes.SAVE_ORGANIZATION_FAILURE;
  constructor(public error: string) {}
}

export class DeleteLicenceAction implements Action {
  readonly type = ActionTypes.DELETE_LICENCE;
  constructor(
    public applicationId: number,
    public licenceId: number,
  ) {}
}

export class DeleteLicenceSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_LICENCE_SUCCESS;
  constructor(
    public applicationId: number,
    public licenceId: number,
  ) {}
}

export class DeleteLicenceFailureAction implements Action {
  readonly type = ActionTypes.DELETE_LICENCE_FAILURE;
  constructor(public error: string) {}
}

export class LoadUnitsAction implements Action {
  readonly type = ActionTypes.LOAD_UNITS;
  constructor(public organizationId: number) {}
}

export class LoadUnitsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_UNITS_SUCCESS;
  constructor(public units: UnitLicences[]) {}
}

export class LoadUnitsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_UNITS_FAILURE;
  constructor(public error: string) {}
}

export class AddUnitAction implements Action {
  readonly type = ActionTypes.ADD_UNIT;
  constructor(
    public organizationId: number,
    public unitName: string,
  ) {}
}

export class AddUnitSuccessAction implements Action {
  readonly type = ActionTypes.ADD_UNIT_SUCCESS;
  constructor(public organizationId: number) {}
}

export class AddUnitFailureAction implements Action {
  readonly type = ActionTypes.ADD_UNIT_FAILURE;
  constructor(public error: string) {}
}

export class SaveUnitAction implements Action {
  readonly type = ActionTypes.SAVE_UNIT;
  constructor(public unit: UnitLicences) {}
}

export class SaveUnitSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_UNIT_SUCCESS;
  constructor(public unit: { id: number; name: string }) {}
}

export class SaveUnitFailureAction implements Action {
  readonly type = ActionTypes.SAVE_UNIT_FAILURE;
  constructor(public error: string) {}
}

export class AddLicenceAction implements Action {
  readonly type = ActionTypes.ADD_LICENCE;
  constructor(
    public unitId: number,
    public validTo: string,
    public featureId: number,
    public applicationId: number,
  ) {}
}

export class AddLicenceSuccessAction implements Action {
  readonly type = ActionTypes.ADD_LICENCE_SUCCESS;
  constructor(
    public licence: {
      id: number;
      unitId: number;
      clusterId: number;
      applicationId: number;
      featureId: number;
      subFeatureId: number | null;
      isFeatureLicence: boolean;
      validTo: string;
      status: string;
    },
  ) {}
}

export class AddLicenceFailureAction implements Action {
  readonly type = ActionTypes.ADD_LICENCE_FAILURE;
  constructor(public error: string) {}
}

export class EditLicencesAction implements Action {
  readonly type = ActionTypes.EDIT_LICENCES;
  constructor(
    public unitId: number,
    public validTo: string,
    public isInvoiceSent: boolean,
    public applicationId: number,
  ) {}
}

export class EditLicencesSuccessAction implements Action {
  readonly type = ActionTypes.EDIT_LICENCES_SUCCESS;
  constructor(
    public licence: {
      id: number;
      unitId: null;
      clusterId: number;
      applicationId: number;
      validTo: string;
      isInvoiceSent: boolean;
      status: string;
    },
  ) {}
}

export class EditLicencesFailureAction implements Action {
  readonly type = ActionTypes.EDIT_LICENCES_FAILURE;
  constructor(public error: string) {}
}

export class AddMemberAction implements Action {
  readonly type = ActionTypes.ADD_MEMBER;
  constructor(
    public organizationId: number,
    public unitId: number,
    public member: User,
  ) {}
}

export class AddMemberSuccessAction implements Action {
  readonly type = ActionTypes.ADD_MEMBER_SUCCESS;
  constructor(
    public user: {
      id: number;
      userCount: number;
      email: string;
      givenName: string;
      familyName: string;
      unitId: number;
      department: Department;
    },
  ) {}
}

export class AddMemberFailureAction implements Action {
  readonly type = ActionTypes.ADD_MEMBER_FAILURE;
  constructor(public error: string) {}
}

export class SaveMemberAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER;
  constructor(
    public unitId: number,
    public member: User,
  ) {}
}

export class SaveMemberSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER_SUCCESS;
  constructor(
    public user: {
      id: number;
      userCount: number;
      email: string;
      givenName: string;
      familyName: string;
      unitId: number;
      department: Department;
    },
  ) {}
}

export class SaveMemberFailureAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER_FAILURE;
  constructor(public error: string) {}
}

export class DeleteMemberAction implements Action {
  readonly type = ActionTypes.DELETE_MEMBER;
  constructor(
    public unitId: number,
    public memberId: number,
  ) {}
}

export class DeleteMemberSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_MEMBER_SUCCESS;
  constructor(
    public unitId: number,
    public memberId: number,
  ) {}
}

export class DeleteMemberFailureAction implements Action {
  readonly type = ActionTypes.DELETE_MEMBER_FAILURE;
  constructor(public error: string) {}
}

export class SaveMemberRolesAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER_ROLES;
  constructor(
    public unitId: number,
    public userId: number,
    public applicationId: number,
    public roles: number[],
  ) {}
}

export class SaveMemberRolesSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER_ROLES_SUCCESS;
  constructor(
    public unitId: number,
    public userId: number,
    public applicationId: number,
    public roles: Role[],
  ) {}
}

export class SaveMemberRolesFailureAction implements Action {
  readonly type = ActionTypes.SAVE_MEMBER_ROLES_FAILURE;
  constructor(
    public unitId: number,
    public userId: number,
    public error: string,
  ) {}
}

export class LoadUnitUsersAction implements Action {
  readonly type = ActionTypes.LOAD_UNIT_USERS;
  constructor(public companyId: number) {}
}

export class LoadUnitUsersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_UNIT_USERS_SUCCESS;
  constructor(public unitUsers: UnitUsers[]) {}
}

export class LoadUnitUsersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_UNIT_USERS_FAILURE;
  constructor(public error: string) {}
}

export class BlockUserAction implements Action {
  readonly type = ActionTypes.BLOCK_USER_ACTION;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class BlockUserSuccessAction implements Action {
  readonly type = ActionTypes.BLOCK_USER_ACTION_SUCCESS;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class BlockUserFailureAction implements Action {
  readonly type = ActionTypes.BLOCK_USER_ACTION_FAILURE;
  constructor(public error: string) {}
}

export class UnblockUserAction implements Action {
  readonly type = ActionTypes.UNBLOCK_USER_ACTION;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class UnblockUserSuccessAction implements Action {
  readonly type = ActionTypes.UNBLOCK_USER_ACTION_SUCCESS;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class UnblockUserFailureAction implements Action {
  readonly type = ActionTypes.UNBLOCK_USER_ACTION_FAILURE;
  constructor(public error: string) {}
}

export class Reset2faAction implements Action {
  readonly type = ActionTypes.RESET_2FA_ACTION;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class Reset2faSuccessAction implements Action {
  readonly type = ActionTypes.RESET_2FA_ACTION_SUCCESS;
}

export class Reset2faFailureAction implements Action {
  readonly type = ActionTypes.RESET_2FA_ACTION_FAILURE;
  constructor(public error: string) {}
}

export class ChangePasswordAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_ACTION;
  constructor(
    public unitId: number,
    public userId: number,
  ) {}
}

export class ChangePasswordSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_ACTION_SUCCESS;
}

export class ChangePasswordFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_PASSWORD_ACTION_FAILURE;
  constructor(public error: string) {}
}

export class LoadDepartmentsAction implements Action {
  readonly type = ActionTypes.LOAD_DEPARTMENTS;
  constructor(public organizationId: number) {}
}

export class LoadDepartmentsSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_DEPARTMENTS_SUCCESS;
  constructor(public departments: Department[]) {}
}

export class LoadDepartmentsFailureAction implements Action {
  readonly type = ActionTypes.LOAD_DEPARTMENTS_FAILURE;
  constructor(public error: string) {}
}

export class AddDepartmentAction implements Action {
  readonly type = ActionTypes.ADD_DEPARTMENT;
  constructor(
    public organizationId: number,
    public departmentName: string,
  ) {}
}

export class AddDepartmentSuccessAction implements Action {
  readonly type = ActionTypes.ADD_DEPARTMENT_SUCCESS;
  constructor(public department: Department) {}
}

export class AddDepartmentFailureAction implements Action {
  readonly type = ActionTypes.ADD_DEPARTMENT_FAILURE;
  constructor(public error: string) {}
}

export class DeleteDepartmentAction implements Action {
  readonly type = ActionTypes.DELETE_DEPARTMENT;
  constructor(public departmentId: number) {}
}

export class DeleteDepartmentSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_DEPARTMENT_SUCCESS;
}

export class DeleteDepartmentFailureAction implements Action {
  readonly type = ActionTypes.DELETE_DEPARTMENT_FAILURE;
  constructor(public error: string) {}
}

export class ResetOrganizationStateAction implements Action {
  readonly type = ActionTypes.RESET_ORGANIZATION_STATE;
}

export type Actions =
  | LoadOrganizationsAction
  | LoadOrganizationsSuccessAction
  | LoadOrganizationsFailureAction
  | LoadOrganizationAction
  | LoadOrganizationSuccessAction
  | LoadOrganizationFailureAction
  | AddOrganizationAction
  | AddOrganizationSuccessAction
  | AddOrganizationFailureAction
  | SaveOrganizationAction
  | SaveOrganizationSuccessAction
  | SaveOrganizationFailureAction
  | DeleteLicenceAction
  | DeleteLicenceSuccessAction
  | DeleteLicenceFailureAction
  | LoadUnitsAction
  | LoadUnitsSuccessAction
  | LoadUnitsFailureAction
  | AddUnitAction
  | AddUnitSuccessAction
  | AddUnitFailureAction
  | SaveUnitAction
  | SaveUnitSuccessAction
  | SaveUnitFailureAction
  | AddLicenceAction
  | AddLicenceSuccessAction
  | AddLicenceFailureAction
  | EditLicencesAction
  | EditLicencesSuccessAction
  | EditLicencesFailureAction
  | AddMemberAction
  | AddMemberSuccessAction
  | AddMemberFailureAction
  | DeleteMemberAction
  | DeleteMemberSuccessAction
  | DeleteMemberFailureAction
  | SaveMemberAction
  | SaveMemberSuccessAction
  | SaveMemberFailureAction
  | SaveMemberRolesAction
  | SaveMemberRolesSuccessAction
  | SaveMemberRolesFailureAction
  | ResetOrganizationStateAction
  | LoadUnitUsersAction
  | LoadUnitUsersSuccessAction
  | LoadUnitUsersFailureAction
  | BlockUserAction
  | BlockUserSuccessAction
  | BlockUserFailureAction
  | UnblockUserAction
  | UnblockUserSuccessAction
  | UnblockUserFailureAction
  | Reset2faAction
  | Reset2faSuccessAction
  | Reset2faFailureAction
  | ChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordFailureAction
  | LoadDepartmentsAction
  | LoadDepartmentsSuccessAction
  | LoadDepartmentsFailureAction
  | AddDepartmentAction
  | AddDepartmentSuccessAction
  | AddDepartmentFailureAction
  | DeleteDepartmentAction
  | DeleteDepartmentSuccessAction
  | DeleteDepartmentFailureAction
  | ResetOrganizationStateAction;
