<div class="relative z-10 flex h-10 flex-col">
  <div
    [ngClass]="
      showLanguageDropdown
        ? 'absolute right-0 top-0 w-[200px] origin-top-right'
        : ''
    "
  >
    <button
      type="button"
      [ngClass]="
        showLanguageDropdown ? 'w-full rounded-t-md bg-brand-blue-550' : ''
      "
      class="flex h-10 items-center justify-between gap-2 p-2 text-[13px]"
      (click)="toggleLanguageDropdown()"
    >
      <img
        src="/assets/icons/Reishauer_Icons-Language.svg"
        alt="Language"
        class="h-5 w-5"
      />

      <span *ngIf="!showLanguageDropdown" class="text-[13px] text-brand-blue">
        {{ selectedLanguage.name }}
      </span>

      <img
        *ngIf="showLanguageDropdown"
        src="/assets/icons/Reishauer_Icons_Close_White.svg"
        alt="Close"
        class="h-4 w-4"
      />
    </button>

    <div *ngIf="showLanguageDropdown">
      <div
        class="options-list w-full overflow-y-auto rounded-b-md bg-brand-blue-550 text-[13px] leading-[13px] text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
      >
        <button
          *ngFor="let language of languages"
          (click)="changeLanguage(language)"
          class="flex h-11 w-full cursor-pointer items-center justify-between px-4 text-white"
          [ngClass]="
            selectedLanguage.code === language.code
              ? ' bg-[#7994C9]'
              : ' hover:bg-[#536EA9]'
          "
        >
          <span>{{ language.name }}</span>
          <img
            src="/assets/icons/icon-check.png"
            alt="Language selected"
            class="h-[24px] w-[24px]"
            *ngIf="selectedLanguage.code === language.code"
          />
        </button>
      </div>
    </div>
  </div>
</div>
